import React from 'react';

import HTML from '../assets/html.png';
import CSS from '../assets/CSS.png';
import JavaScript from '../assets/javascript.png';
import ReactImg from '../assets/React.png';
import Node from '../assets/node.png';
import MySQL from '../assets/MySQL.png';

const Skills = () => {
  return (
    <div name='skills' className='w-full h-screen bg-[#1f1f29] text-gray-300'>
      {/* Container */}
      <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
          <div>
              <p className='text-4xl font-bold inline border-b-2 border-[#52eedf] '>Skills</p>
              <p className='py-4'>These are the technologies I've worked with</p>
          </div>

          <div className='w-full grid grid-cols-2 sm:grid-cols-4 gap-4 text-center py-8'>
              <div className='shadow-md shadow-[#52eedf] hover:scale-110 duration-500'>
                  <img className='w-20 mx-auto' src={HTML} alt="HTML icon" />
                  <p className='my-4'>HTML</p>
              </div>
              <div className='shadow-md shadow-[#52eedf] hover:scale-110 duration-500'>
                  <img className='w-20 mx-auto' src={CSS} alt="CSS icon" />
                  <p className='my-4'>CSS</p>
              </div>
              <div className='shadow-md shadow-[#52eedf] hover:scale-110 duration-500'>
                  <img className='w-20 mx-auto' src={JavaScript} alt="JavaScript icon" />
                  <p className='my-4'>JAVASCRIPT</p>
              </div>
              <div className='shadow-md shadow-[#52eedf] hover:scale-110 duration-500'>
                  <img className='w-20 mx-auto' src={ReactImg} alt="React icon" />
                  <p className='my-4'>REACT</p>
              </div>
              <div className='shadow-md shadow-[#52eedf] hover:scale-110 duration-500'>
                  <img className='w-20 mx-auto' src={Node} alt="NodeJs icon" />
                  <p className='my-4'>NODE JS</p>
              </div>
              <div className='shadow-md shadow-[#52eedf] hover:scale-110 duration-500'>
                  <img className='w-20 mx-auto' src={MySQL} alt="MySQL icon" />
                  <p className='my-4'>MYSQL</p>
              </div>
          </div>
      </div>
    </div>
  );
};

export default Skills;
